import React, { Component } from "react";
import Helmet from "react-helmet"; 
import { graphql } from "gatsby"; 

import { Learning } from "../../components/learning/learning";
import Newsletter from "../../components/newsletter/newsletter";
import Section from "../../components/common/Section";
import Layout from "../../layouts";
import { Container, Row, Col } from "react-bootstrap";
import Hero from "../../components/common/Hero";
import Metrics from "../../components/metrics/metrics.js"; 
import SEO from "../../components/common/SEO"; 

class MetricPage extends Component {
  render() {
    const data = this.props.data
    const metrics = data.allLatestMetrics.nodes;

    return (
      <Layout>
        <Helmet bodyAttributes={{ class: "metrics-page" }} />        
        <SEO post={{
          title: "Compare US Presidents across various metrics",
          description: "Facts First compares previous presidents across various metrics such as jobs created, GDP growth and stock market performance"
        }} />
        <Hero title="Find A Metric To Compare Performance"></Hero>
        
        <Container className='metrics-sections intro'>
          <p>
          We track critical economic indicators that affect US consumers and
          businesses in a direct way. Our goal is to provide facts on how these
          metrics are performing under different American presidents.
          <br /> <br />
          We are currently tracking three metrics and are working hard to add
          more in the very near future.
          </p>
          <Row className={'metrics'}><Col><Metrics metrics={metrics} /></Col></Row>  
        </Container>

        <Container
          className={["metrics-sections"]}
          style={{ marginBottom: "50px" }}
        >
          <Row>
            <Col>
              <Section
                name="metrics_calculation"
                header="How We Calculate Our Data"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Learning title='Learn more about our metrics'/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Newsletter />
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default MetricPage;


export const query = graphql`
  {
    allLatestMetrics {
      nodes {
        id
        value
        totalGain
        percentGain
        name
        annualizedGain
        date
      }
    }
  }
`; 