import React, { Component } from 'react';

class Hero extends Component {
  render() {
    return (      
	    <div id="hero" className={this.props.color || "page-hero"}>
        <div className="hero-text">
          <h2 className="text">{this.props.title}</h2>
        </div>
	  </div>              
    );
  }
}

export default Hero;
